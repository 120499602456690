export default class Search {

    static get()
    {
        const btn = document.querySelector('.main-header__search');

        if (btn) {
            const body = document.querySelector('body');
            const s_box = document.querySelector('.main-header__search-box');
            const s_close = document.querySelector('.close-modal-search');

            btn.addEventListener('click', () => {
                body.style.overflowY = 'hidden';
                s_box.style.display = 'block';
            });

            s_close.addEventListener('click', () => {
                body.style.overflowY = 'auto';
                s_box.style.display = 'none';
            });
        }
    }
}
