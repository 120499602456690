import axios from 'axios';

export default class Forms {

    static get() {
        // Report a bug
        const report_bug_form = document.querySelector('.report-bug-form');

        if (report_bug_form) {
            const scs_box = document.querySelector('.main-form-grid--scs');

            report_bug_form.addEventListener('submit', (e) => {
                e.preventDefault();

                const target = e.target;

                let form_data = new FormData;
                form_data.append('action', 'form_report_bug');
                form_data.append('name', target.querySelector('input[name="name"]').value);
                form_data.append('email', target.querySelector('input[name="email"]').value);
                form_data.append('calc_id', target.querySelector('select[name="calc"]').value);
                form_data.append('msg', target.querySelector('textarea[name="msg"]').value);

                target.style.display = 'none';
                scs_box.classList.toggle('-active');

                axios.post(myajax.url, form_data)
                    .then(function (response) {
                        // console.warn(response.data);
                    })
                    .catch(function (response) {
                        console.error(response);
                    });
            });
        }

        // Contacts
        const contact_form = document.querySelector('.contact-form');

        if (contact_form) {
            const scs_box = document.querySelector('.main-form-grid--scs');

            contact_form.addEventListener('submit', (e) => {
                e.preventDefault();

                const target = e.target;

                let form_data = new FormData;
                form_data.append('action', 'form_contact');
                form_data.append('name', target.querySelector('input[name="name"]').value);
                form_data.append('email', target.querySelector('input[name="email"]').value);
                form_data.append('msg', target.querySelector('textarea[name="msg"]').value);

                target.style.display = 'none';
                scs_box.classList.toggle('-active');

                axios.post(myajax.url, form_data)
                    .then(function (response) {
                        // console.warn(response.data);
                    })
                    .catch(function (response) {
                        console.error(response);
                    });
            });
        }

    }
}
