import Forms from './Parts/Forms';
import Search from './Parts/Search';
import CalcTab from './Parts/CalcTab';

// Forms
Forms.get();

// Search
Search.get();

// CalcTab
CalcTab.get();

(() => {
    const lang_btn = document.querySelector('.main-header__langs .pll-parent-menu-item a');

    if (lang_btn) {
        lang_btn.addEventListener('click', (e) => {
            e.preventDefault();

            const lang_ul = document.querySelector('.main-header__langs .sub-menu');
            lang_ul.classList.toggle('-active');
        });
    }
})();

// Calc form fix comma
(() => {
    const form = document.querySelectorAll('.calc-grid input[type="text"]');

    if (form) {
        for (let i = 0; i < form.length; i++) {
            form[i].addEventListener('keyup', (e) => {

                let value = e.target.value.replace(',', '.');
                e.target.value = value;
            });
        }
    }
})();

