export default class CalcTab {

    static get() {
        const btn = document.querySelectorAll('.calc-tab__header .label');

        if (btn) {
            const tab_content = document.querySelectorAll('.calc-tab__content');

            for (let i = 0; i < btn.length; i++) {
                btn[i].addEventListener('click', (e) => {
                    e.preventDefault();

                    const target = e.target;

                    for (let z = 0; z < btn.length; z++)
                        btn[z].classList.remove('-active');

                    target.classList.add('-active');

                    const id = target.dataset.id;

                    for (let n = 0; n < tab_content.length; n++) {
                        tab_content[n].classList.remove('-active');

                        if (tab_content[n].dataset.id === id)
                            tab_content[n].classList.add('-active');
                    }

                });
            }
        }
    }
}